import React from "react"
import { Link, StaticQuery, graphql } from "gatsby"

const Nav = () => (
  <div className="uk-section-secondary uk-section" uk-height-viewport="expand: true;" data-height-expand="">
    <div className="uk-container">
      <div className="uk-grid-large uk-grid-margin-large uk-grid" uk-grid="">
        <div className="uk-flex-auto uk-width-1-4@l uk-width-1-2@m uk-first-column">
          <div className="uk-margin">
            <StaticQuery
              query={graphql`
                    query  {
                    strapiAbouts(slug: {eq: "motoabout"}) {
                      image {
                        childImageSharp {
                          fixed(width: 100, height: 100) {
                            src
                            height
                            width
                          }
                        }
                      }
                    }
                  }
                  `}
              render={({ strapiAbouts }) => {
                return <Link to="/" className="uk-navbar-item uk-logo">
                  {/* <img alt="moto minuto" src="http://localhost:1337/uploads/logo_0de0efda1d.png" /> */}
                  <img width="45" className="uk-logo-inverse" alt="Max" src={strapiAbouts.image.childImageSharp.fixed.src} />
                </Link>
              }}
            />

          </div>
        </div>
        {/* <div className="uk-flex-auto uk-width-1-4@l uk-width-1-2@m">
        <h2 className="uk-h5">Contact</h2>
        <ul className="uk-list">        
        <li className="el-item">
          <div className="uk-child-width-expand uk-grid-small uk-grid" uk-grid="">
            <div className="uk-width-auto uk-first-column">
              <span className="el-title uk-display-block">Phone:</span>
            </div>
            <div>
              <div className="el-content uk-panel"><a href="tel:5550199">555-0199</a></div>
            </div>
          </div>
        </li>
          <li className="el-item">
            <div className="uk-child-width-expand uk-grid-small uk-grid" uk-grid="">
              <div className="uk-width-auto uk-first-column">
                <span className="el-title uk-display-block">E-Mail:</span>
              </div>
              <div>
                <div className="el-content uk-panel"><a href="mailto:max@example.com">motominutoph@gmail.com</a></div>
              </div>
            </div>
          </li>
        </ul>
      </div> */}
        <div className="uk-flex-auto uk-width-1-4@l uk-width-1-2@m">
          <h2 className="uk-h5">Social</h2>
          <ul className="uk-list">
            <li className="el-item">
              <div className="el-content uk-panel">
                <a href="https://www.instagram.com/motominutoph" className="el-link">Instagram: @motominuto</a></div></li>
            <li className="el-item">
              <div className="el-content uk-panel">
                <a href="https://www.facebook.com/motominutoph" className="el-link">Facebook: Moto Minuto</a></div></li>
          </ul>




        </div>
        <div className="uk-flex-auto uk-width-1-4@l uk-width-1-2@m">
          <h2 className="uk-h5">Documents</h2>
          <ul className="uk-list">        <li className="el-item">
            <div className="el-content uk-panel"><a href="/privacy-policy" className="el-link">Privacy Policy</a></div></li>
            <li className="el-item">
              <div className="el-content uk-panel"><a href="/terms-of-service" className="el-link">Terms of Service</a></div></li>
          </ul>




        </div>

        <div className="uk-flex-auto uk-width-1-4@l uk-width-1-2@m">




          <div className="uk-margin uk-text-right@m"><a href="#" uk-totop="" uk-scroll="" className="uk-icon uk-totop"><svg width="15" height="22" viewBox="0 0 15 20" xmlns="http://www.w3.org/2000/svg" data-svg="totop"><polyline fill="none" stroke="#0000" stroke-width="1.1" points="1,8 7.5,1.5 14,8"></polyline><rect fill="0" x="7" y="2" width="1" height="20"></rect></svg></a></div>



        </div>
      </div>
    </div>

  </div>
)

export default Nav  