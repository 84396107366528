import React from "react"
import { Link, StaticQuery, graphql } from "gatsby"

const Nav = (path) => {

  return (
    <StaticQuery
      query={graphql`
                    query  {
                    strapiAbouts(slug: {eq: "motoabout"}) {
                      image {
                        childImageSharp {
                          fixed(width: 100, height: 100) {
                            src
                            height
                            width
                          }
                        }
                      }
                    }
                  }
                  `}
      render={({ strapiAbouts }) => {
        // console.log('window.location', window.location.pathname.includes("about-us"))
        return (
          <>
            <div className="tm-header-mobile uk-hidden@m">
              <div className="uk-navbar-container">
                <nav uk-navbar="" className="uk-navbar">
                  <div className="uk-navbar-left">
                    <Link to="/" className="uk-navbar-item uk-logo">
                      {/* <img alt="moto minuto" src="http://localhost:1337/uploads/logo_0de0efda1d.png" /> */}
                      <img width="45" className="uk-logo-inverse" alt="Moto Minuto" src={strapiAbouts.image.childImageSharp.fixed.src} />
                      <div className="uk-text-small uk-margin-left"> MOTO MINUTO </div>
                    </Link>
                  </div>
                  <div className="uk-navbar-right">
                    <a className="uk-navbar-toggle" href="#tm-mobile" uk-toggle="">
                      <div uk-navbar-toggle-icon="" className="uk-icon uk-navbar-toggle-icon"><svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="navbar-toggle-icon"><rect y="9" width="20" height="2"></rect><rect y="3" width="20" height="2"></rect><rect y="15" width="20" height="2"></rect></svg></div>
                    </a>
                  </div>
                </nav>
              </div>
              <div id="tm-mobile" className="uk-modal-full uk-modal" uk-modal="">
                <div className="uk-modal-dialog uk-modal-body uk-text-center uk-flex uk-height-viewport">
                  <button className="uk-modal-close-full uk-icon uk-close" type="button" uk-close=""><svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg" data-svg="close-icon"><line fill="none" stroke="#000" stroke-width="1.1" x1="1" y1="1" x2="13" y2="13"></line><line fill="none" stroke="#000" stroke-width="1.1" x1="13" y1="1" x2="1" y2="13"></line></svg></button>
                  <div className="uk-margin-auto-vertical uk-width-1-1">
                    <div className="uk-child-width-1-1 uk-grid" uk-grid="">    <div>
                      <div className="uk-panel">
                        <ul className="uk-nav uk-nav-primary uk-nav-center">
                          <li className={`uk-active`}><a href="/" className=" menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-16 current_page_item">HOME</a></li>
                          <li className={'uk-active'}><a href="/about-us" className=" menu-item menu-item-type-post_type menu-item-object-page">ABOUT</a></li>
                          <li className={'uk-active'}><a href="/all/howtos-and-guides" className=" menu-item menu-item-type-post_type menu-item-object-page">HOW TOs & GUIDES</a></li>
                          {/* <li className={window.location.pathname === "/" ? `uk-active` : ''}><a href="/" className=" menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-16 current_page_item">Home</a></li>
                          <li className={window.location.pathname.includes("about-us") ? `uk-active` : ''}><a href="/about-us" className=" menu-item menu-item-type-post_type menu-item-object-page">About</a></li> */}
                          {/* <li className="uk-parent"><a href="https://demo.yootheme.com/themes/wordpress/2016/max/?page_id=158" className=" menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children">Shop</a>
                          <ul className="uk-nav-sub" style={{ display: 'none' }}>

                            <li><a href="https://demo.yootheme.com/themes/wordpress/2016/max/?page_id=159" className=" menu-item menu-item-type-post_type menu-item-object-page">Cart</a></li>
                            <li><a href="https://demo.yootheme.com/themes/wordpress/2016/max/?page_id=161" className=" menu-item menu-item-type-post_type menu-item-object-page">My account</a></li></ul></li> */}
                        </ul>

                      </div>
                    </div></div>
                  </div>

                </div>
              </div>
            </div>
            <div className="tm-header uk-visible@m tm-header-overlay" uk-header="">
              <div uk-sticky="" media="@m" show-on-up="" animation="uk-animation-slide-top" cls-active="uk-navbar-sticky" sel-target=".uk-navbar-container" className="uk-sticky  ">
                <div className="uk-navbar-container uk-light">
                  <div className="uk-container">
                    <nav className="uk-navbar" uk-navbar="{'align':'left','boundary':'!.uk-navbar-container','dropbar':true,'dropbar-anchor':'!.uk-navbar-container','dropbar-mode':'slide'}">
                      <div className="uk-navbar-left">

                        <Link to="/" className="uk-navbar-item uk-logo">
                          <img width="45" className="uk-logo-inverse" alt="Moto Minuto" src={strapiAbouts.image.childImageSharp.fixed.src} />
                          <div className="uk-text-medium uk-margin-left"> MOTO MINUTO </div>
                        </Link>

                      </div>
                      <div className="uk-navbar-center">
                        <ul className="uk-navbar-nav">
                          <li className="uk-active"><a href="/" className=" menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-16 current_page_item">HOME</a></li>
                          <li className="uk-active"><a href="/about-us" className=" menu-item menu-item-type-post_type menu-item-object-page">ABOUT</a></li>
                          <li className="uk-active"><a href="/all/howtos-and-guides" className=" menu-item menu-item-type-post_type menu-item-object-page">HOW TOs & GUIDES</a></li>
                          {/* <li className="uk-parent">
                          <a href="/shop" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children" aria-expanded="false">Shop</a>
                          <div className="uk-navbar-dropdown uk-animation-fade uk-animation-enter uk-navbar-dropdown-dropbar" style={{ left: '584.359px', top: '80px', 'animation-duration': '200ms' }}>
                            <div className="uk-navbar-dropdown-grid uk-child-width-1-1 uk-grid uk-grid-stack" uk-grid="">
                              <div className="uk-first-column">
                                <ul className="uk-nav uk-navbar-dropdown-nav">
                                  <li><a href="#" className=" menu-item menu-item-type-post_type menu-item-object-page">Cart</a></li>
                                  <li><a href="#" className=" menu-item menu-item-type-post_type menu-item-object-page">My account</a></li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </li> */}
                        </ul>
                      </div>
                      <div className="uk-navbar-right">
                        <div className="uk-navbar-item">
                          <ul className="uk-grid-small uk-flex-inline uk-flex-middle uk-flex-nowrap uk-grid" uk-grid="">
                            <li className="uk-first-column">
                              <a href="https://instagram.com/motominutoph" className="uk-icon-link uk-icon" uk-icon="instagram">
                                <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="instagram"><path d="M13.55,1H6.46C3.45,1,1,3.44,1,6.44v7.12c0,3,2.45,5.44,5.46,5.44h7.08c3.02,0,5.46-2.44,5.46-5.44V6.44 C19.01,3.44,16.56,1,13.55,1z M17.5,14c0,1.93-1.57,3.5-3.5,3.5H6c-1.93,0-3.5-1.57-3.5-3.5V6c0-1.93,1.57-3.5,3.5-3.5h8 c1.93,0,3.5,1.57,3.5,3.5V14z"></path><circle cx="14.87" cy="5.26" r="1.09"></circle><path d="M10.03,5.45c-2.55,0-4.63,2.06-4.63,4.6c0,2.55,2.07,4.61,4.63,4.61c2.56,0,4.63-2.061,4.63-4.61 C14.65,7.51,12.58,5.45,10.03,5.45L10.03,5.45L10.03,5.45z M10.08,13c-1.66,0-3-1.34-3-2.99c0-1.65,1.34-2.99,3-2.99s3,1.34,3,2.99 C13.08,11.66,11.74,13,10.08,13L10.08,13L10.08,13z"></path></svg>
                              </a>
                            </li>
                            <li>
                              <a href="https://www.facebook.com/motominutoph" className="uk-icon-link uk-icon" uk-icon="facebook">
                                <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="facebook"><path d="M11,10h2.6l0.4-3H11V5.3c0-0.9,0.2-1.5,1.5-1.5H14V1.1c-0.3,0-1-0.1-2.1-0.1C9.6,1,8,2.4,8,5v2H5.5v3H8v8h3V10z"></path></svg></a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </nav>
                  </div>
                </div>
                <div className="uk-navbar-dropbar uk-navbar-dropbar-slide" style={{ height: 0 }}></div>
              </div>
              {/* <div className="uk-sticky-placeholder" style={{ height: '80px', margin: 0 }} hidden=""></div> */}
            </div>
          </>
        )
      }
      }
    />

  )
}

export default Nav
